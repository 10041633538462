<template>
  <v-dialog v-model="modalData.dialog" persistent max-width="650px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        v-if="permissionCan('create')"
      >
        New currency
      </v-btn>
    </template>

    <v-card>
      <v-form v-model="formValid" ref="form">
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span class="headline">{{ formTitle }} </span>
              <v-btn
                @click="handleCloseModalForm"
                style="float: right; cursor: pointer"
                icon
                color="#26223c"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container v-if="formModel">
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="formModel.id"
                  :rules="idRules"
                  label="Currency"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="formModel.sign"
                  :rules="signRules"
                  label="Sign"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="formModel.exchange_rate"
                  :rules="exchangeRateRules"
                  label="Exhange rate to HUF"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-if="formModel.format"
                  v-model="formModel.format"
                  label="Format"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseModalForm">
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="handleSaveModalForm"
            v-if="
              (permissionCan('create') && this.modalData.editedIndex === -1) ||
              permissionCan('update')
            "
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
export default {
  name: "CurrencyForm",
  props: ["modalData", "permissions", "loader"],
  data() {
    return {
      formValid: false,
      selectedLocale: this.modalData.selectedLocale,
      exchangeRateRules: [
        (v) => !!v || "Exchange Rate is required",
        (v) => v > 0 || "Exchange Rate must be greater than 0",
      ],
      idRules: [
        (v) => !!v || "Id is required",
        (v) => v.length == 3 || "Id must be 3 characters",
      ],
      signRules: [
        (v) => !!v || "Sign is required",
        (v) => v.length < 4 || "Sign must be max 3 characters",
      ],
    };
  },
  computed: {
    formTitle() {
      return this.modalData.editedIndex === -1
        ? "New Currency"
        : "Edit Currency";
    },
    formModel() {
      return this.modalData.editedItem;
    },
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
  },

  methods: {
    handleCloseModalForm() {
      this.$refs.form.resetValidation();
      this.$emit("closeModalForm");
    },
    handleSaveModalForm() {
      this.$refs.form.validate();
      if (this.formValid) {
        this.$refs.form.resetValidation();
        this.$emit("saveModalForm", this.formModel);
      }
    },
    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "system.currency." + action
      );
    },
  },
};
</script>
